<template>
    <div class="mainSpyContainer">
        <Egit></Egit>
        <Casusuluk></Casusuluk>
    </div>
</template>

<script>
    import Egit from './Egit';
    import Casusuluk from './Casusuluk';

    export default {
        components: {
            Egit,Casusuluk
        }
    }
</script>



